<template>
  <v-col
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
  >
    <base-card
      :height="value.prominent ? 450 : 350"
      color="grey lighten-1"
      dark
      :href="value.url"
      target="_blank"
    >
      <v-img
        :src="require(`@/assets/events/${value.image}`)"
        height="100%"
        gradient="rgba(0, 0, 0, .42), rgba(0, 0, 0, .42)"
      >
        <v-row
          v-if="!value.prominent"
          class="fill-height text-right ma-0"
        >
          <v-col cols="12">
            <v-chip
              label
              class="mx-0 mb-2 text-uppercase"
              color="grey darken-3"
              text-color="white"
              small
              @click.stop=""
            >
              {{ content.category }}
            </v-chip>

            <h3 class="title font-weight-bold mb-2">
              {{ content.title }}
            </h3>

            <div class="caption">
              {{ content.description }}
            </div>
          </v-col>

          <v-col align-self="end">
          </v-col>
        </v-row>
      </v-img>
    </base-card>
  </v-col>
</template>

<script>
  export default {
    name: 'EventCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      content () {
        return this.value[this.$store.state.lang]
      },
    },
  }
</script>

<style>
  .v-image__image {
    transition: .3s linear;
  }
</style>
